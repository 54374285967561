<template>
  <table-view
    ref="tableView"
    url="/quantityNumber/info/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :uploadable="isAuthed('quantity_record_import')"
    upload-title="批量上传方量明细"
    upload-url="/quantityNumber/info/batch"
    :exportable="true"
    export-url="/quantityNumber/info/download"
    export-file-name="方量报表"
    :headers="headers"
    show-index
    single
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-form-item label="生产日期">
        <el-date-picker type="date" v-model="dataForm.startDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="到">
        <el-date-picker type="date" v-model="dataForm.endDate" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-select filterable clearable v-model="dataForm.carGasType" placeholder="类型">
        <el-option
          v-for="s in carGasTypeList"
          :key="s.dictId"
          :label="s.dictName"
          :value="s.dictName"
        ></el-option>
      </el-select>
      <el-select filterable clearable v-model="dataForm.insider" placeholder="局内局外">
        <el-option label="局内" value="局内"></el-option>
        <el-option label="局外" value="局外"></el-option>
      </el-select>
      <el-select filterable clearable v-model="dataForm.carsize" placeholder="泵别">
        <el-option v-for="s in carSizeList" :key="s" :label="s" :value="parseInt(s)"></el-option>
      </el-select>
      <el-select filterable clearable v-model="dataForm.carType" placeholder="车辆类型">
        <el-option label="自有" :value="1"></el-option>
        <el-option label="租赁" :value="2"></el-option>
        <el-option label="外借" :value="3"></el-option>
      </el-select>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'report-amount',

  components: { TableView },

  data () {
    return {
      carGasTypeList: [],
      carSizeList: [],
      headers: [
        { id: 1, prop: 'dispatchTime', label: '生产日期', width: 100 },
        { id: 2, prop: 'number', label: '车辆自编号', width: 120 },
        { id: 3, prop: 'licensePlate', label: '车牌号码', width: 100 },
        { id: 4,
          prop: 'carGasType',
          label: '类型',
          width: 120,
          formatter: (row, column, cellValue) => {
            switch (cellValue) {
              case 1:
                return '汽'
              case 2:
                return '固'
              case 3:
                return '外汽'
              case 4:
                return '外固'
              default:
                return '未知'
            }
          } },
        {
          id: 5,
          prop: 'carType',
          label: '车辆类型',
          width: 100,
          formatter: (row, column, cellValue) => {
            switch (cellValue) {
              case 1:
                return '自有'
              case 2:
                return '租赁'
              case 3:
                return '外借'
              default:
                return '未知'
            }
          }
        },
        { id: 6, prop: 'carSize', label: '泵别', width: 60 },
        { id: 7, prop: 'companyAbbr', label: '分部分项', width: 100 },
        { id: 9, prop: 'constructionUnit', label: '施工单位', width: 150 },
        { id: 10, prop: 'siteName', label: '工程项目', minWidth: 150 },
        { id: 11, prop: 'siteAddress', label: '工地地址', minWidth: 150 },
        { id: 12, prop: 'baseName', label: '基地名称', minWidth: 100 },
        { id: 13, prop: 'stationName', label: '站点名称', minWidth: 100 },
        { id: 14, prop: 'mixingTable', label: '拌站', width: 100 },
        { id: 15, prop: 'insider', label: '局内局外', width: 100 },
        { id: 16, prop: 'carUnitPrice', label: '泵单价', width: 100 },
        { id: 17, prop: 'statementQuantityNumber', label: '结算泵量', width: 100 },
        { id: 18, prop: 'statementPrice', label: '结算泵费', width: 100 },
        { id: 19, prop: 'confirmQuantityNumber', label: '确认泵量', width: 100 },
        { id: 20, prop: 'confirmPrice', label: '确认泵费', width: 100 },
        { id: 21, prop: 'disputeQuantityNumber', label: '争议泵量', width: 100 },
        { id: 22, prop: 'disputePrice', label: '争议金额', width: 100 },
        { id: 23, prop: 'createTime', label: '创建时间', width: 150 },
        { id: 24, prop: 'remarkOne', label: '备注一', width: 100 },
        { id: 25, prop: 'remarkTwo', label: '备注二', width: 100 }
      ],
      dataForm: {
        startDate: '',
        endDate: '',
        carGasType: '',
        insider: '',
        carsize: '',
        carSizeOperator: '=',
        carType: ''
      }
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  },

  async created () {
    let data = await this.$http({
      url: this.$http.adornUrl('/car/otherData'),
      method: 'post'
    })
    this.carGasTypeList = data.datas.carGasType
    data = await this.$http({
      url: this.$http.adornUrl('/car/listCarSize'),
      method: 'post'
    })
    this.carSizeList = data.datas
  }
}
</script>
